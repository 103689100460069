import "./App.css";
import logo from "./logo.png";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { ImLinkedin } from "react-icons/im";
import { BsInstagram } from "react-icons/bs";
import { SiGmail } from "react-icons/si";
import Particles from "react-tsparticles";

function App() {
  const particlesInit = (main) => {
    // console.log(main);
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container) => {
    // console.log(container);
  };

  return (
    <div className="landing">
      <Parallax pages={5} style={{ top: "0", left: "0", background: "black" }}>
        <ParallaxLayer
          offset={0}
          speed={0.4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1 className="title">KIMERA.</h1>
          <img src={logo} alt="Cimera Logo" id="logo" />
        </ParallaxLayer>

        <ParallaxLayer
          offset={0}
          speed={0.1}
          style={{
            // backgroundImage: "url(/assets/images/landing.png)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            zIndex: -1,
          }}
        >
          <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              background: {
                color: {
                  value: "#000",
                },
              },
              fpsLimit: 60,
              interactivity: {
                events: {
                  onClick: {
                    enable: false,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  bubble: {
                    distance: 400,
                    duration: 5,
                    opacity: 0.8,
                    size: 40,
                  },
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 50,
                    duration: 5,
                  },
                },
              },
              particles: {
                color: {
                  value: "#ffffff",
                },
                links: {
                  color: "#ffffff",
                  distance: 160,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                collisions: {
                  enable: true,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outMode: "bounce",
                  random: true,
                  speed: 2,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    value_area: 1300,
                  },
                  value: 100,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  random: true,
                  value: 5.5,
                },
              },
              detectRetina: true,
            }}
          />
          {/* <div className="landing-container"></div> */}
        </ParallaxLayer>

        {/* <ParallaxLayer
          offset={1}
          speed={3}
          style={{ backgroundColor: "red" }}
        /> */}
        <ParallaxLayer
          offset={1}
          speed={0.3}
          style={{
            color: "black",
            height: "max-content",
          }}
        >
          <div className="about">
            <h1>About Us</h1>
            <div className="quotes">
              <h4 className="decor">&quot;</h4>
              <p>
                “As far as moving images are concerned, it’s a matter of what’s
                in the frame and what’s out.”
              </p>
              <p> ~ Martin Scorsese</p>
            </div>
            <p>
              We at Kimera, believe in the power of moving images and its
              ability to capture time itself. Today the industry is caught in a
              fatigue of creating and consuming repetitive content. Mediocrity
              has become a norm, and since everyone’s okay with that, new and
              revolutionary things are scarce. A wave of new age content is
              imminent. With our core team’s varied expertise, Kimera intends to
              lead the way into this new era of deeply layered and well crafted
              visual content. We are a power packed video production company
              catering to all your production needs. Kimera Films is constantly
              pushing the limits of visual storytelling while making sure that
              every project gets special care & attention at all times and
              stages of production. Kimera Films is constantly pushing the
              limits of visual storytelling.
            </p>
            <p className="quote2">
              “In order to create ads, one simply can’t rely on just data as its
              strength, but also has to rely on the creativity.”
            </p>
            <p>
              The idea for Kimera came out of a desire and need to ramp up the
              standards of content generation. Be it a product shoot or an ad
              film, a music video or a feature film, we make sure that
              everything we create is both well crafted and aesthetically
              pleasing.
            </p>
          </div>
        </ParallaxLayer>

        <ParallaxLayer
          offset={2}
          speed={1}
          style={{
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
            // background: "linear-gradient(wheat 90%,#fff)",
            background: "#262728",
            backgroundSize: "400% 400%",

            // "-webkit-animation": "AnimationName 10s ease infinite",
            // " -moz-animation": "AnimationName 10s ease infinite",
            // "-o-animation": "AnimationName 10s ease infinite",
            // animation: " AnimationName 10s ease infinite",
          }}
          className="grd-bck"
        />

        <ParallaxLayer
          offset={2}
          speed={0.7}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "black",
          }}
        >
          <h1 className="ourTeam">Meet Our Team</h1>
          <div className="team">
            <div className="team-card">
              <h2>HASSAAN AHMAD</h2>
              <h3>Head of Content Development</h3>
              <p>
                A self-trained dual blade wielder, trained in the arts of
                Writing and Direction. Hassaan is responsible for ideation,
                conceptualisation, and spearheading the process of
                pre-production while overwatching parts of production and post
                production.
              </p>
            </div>
            <div className="team-card">
              <h2>TUSHAR SINGH</h2>
              <h3>Head of Production & Marketing</h3>
              <p>
                A true magician with people. From marketing to casting to
                overseeing enormous crews, he makes it all look effortless. With
                years of experience both in front and behind the camera, Tushar
                is Leading the way for Kimera’s marketing, Production and Sales.
              </p>
            </div>
            <div className="team-card">
              <h2>BASHAR PARVEZ</h2>
              <h3>Head of Post-Production</h3>
              <p>
                The powerhouse cyborg of Kimera. The machines are sentient
                beings but with his superior technical prowess and knowledge
                under his rule they listen to him. Bashar has honed and mastered
                all the domains of Post Production, be it Editing, Animation or
                SFX.
              </p>
            </div>
          </div>
        </ParallaxLayer>

        <ParallaxLayer
          offset={3}
          speed={0.3}
          style={{
            backgroundImage: "url(/assets/images/last.png)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            opacity: 1,
          }}
        >
          <div className="ourServices-head">
            <h2>Our Services</h2>
          </div>
        </ParallaxLayer>

        <ParallaxLayer
          offset={4}
          speed={0.2}
          style={{
            // background: "linear-gradient(white , #e0ac60 80%)",
            // height: "max-content",
            // backgroundImage: "url(/assets/images/last.png)",
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "cover",
            opacity: 1,
          }}
        >
          <div className="ourServices">
            <p>
              <span className="yellowUnder"> Kimera </span> offers both end to
              end service for all kinds of production as well as renting out
              different services like&nbsp;
              <span className="yellowUnder">art-direction</span>,&nbsp;
              <span className="yellowUnder">photo</span> and&nbsp;
              <span className="yellowUnder">video editing</span>,&nbsp;
              <span className="yellowUnder">copywriting</span> and&nbsp;
              <span className="yellowUnder">scriptwriting</span>, etc. However
              we understand that in the world of content creation there’s no
              concrete structure. We understand that every individual has a
              different need and vision, and what might be good for one but not
              be ideal for the other. Therefore, we believe that in order to
              create something new, one needs to pay attention and listen to
              what makes it stand out.
            </p>
            <div className="serviceList">
              <div>
                <p>AD Films</p>
                <p>Fashion Campaign Shoots</p>
                <p>Documentary & Short Films</p>
                <p>Digital Advertisement</p>
                <p>Product Video shoot</p>
              </div>
              <div>
                <p>Video-Editing</p>
                <p>Music Videos</p>
                <p>Script Writing</p>
                <p>Graphic Design</p>
                <p>Colour Correction/Grading</p>
              </div>
            </div>
            <div className="footer">
              <a className="hovc" href="https://www.instagram.com/kimerafilms/">
                <BsInstagram />
              </a>
              <a className="hovc" href="https://www.linkedin.com/company/kimera-films21/?viewAsMember=true">
                <ImLinkedin />
              </a>
              <a className="hovc" href="mailto:filmsbykimera@gmail.com">
                <SiGmail />
              </a>
              <a href="#">+ 91 8882264140</a>
            </div>
          </div>
        </ParallaxLayer>
      </Parallax>
    </div>
  );
}

export default App;
